<template>
  <div class="_riverside-cottage">
    <section class="_section__hero-wrapper">
      <section class="_section__riverside-cottage--intro">
        <div class="_media">
          <picture class="_picture--1">
            <source
              media="(min-width: 1200px)"
              srcset="@/assets/images/riverside_hero_1-1200.jpg"
            />
            <source
              media="(min-width: 600px)"
              srcset="@/assets/images/riverside_hero_1-Small.jpg"
            />
            <img
              class="_image"
              src="@/assets/images/riverside_hero_1-Small.jpg"
              alt="riverside cottage 1"
            />
          </picture>
          <picture class="_picture--2">
            <source
              media="(min-width: 1200px)"
              srcset="@/assets/images/riverside_hero_2-1200.jpg"
            />
            <source
              media="(min-width: 600px)"
              srcset="@/assets/images/riverside_hero_2-Small.jpg"
            />
            <img
              class="_image"
              src="@/assets/images/riverside_hero_2-Small.jpg"
              alt="riverside cottage 2"
            />
          </picture>
        </div>
        <div class="_card--raised">
          <h2 class="_title">3 Bedroom Riverside Cottage</h2>
          <div class="_paragraphs">
            <p class="_text">
              We believe in cherishing every moment as it arrives. This fast
              paced city life often disconnects us with our present. Sometimes
              all we need is to log ourselves out of the daily hustle and slow
              down a little. The Riverside cottage is a homey space for such
              offline intimate gatherings. Let the ambience dazzle you.
            </p>
            <p class="_text">
              The cottage consists of a living room and 3 cozy rooms. The living
              room window opens up to the gently flowing Pej river. Savour a cup
              of tea on the porch or finish that unfinished book. The riverside
              cottage will assure a relaxing weekend filled with warmth and
              laughter just like those good ol' days we often miss.
            </p>
          </div>
        </div>
      </section>
    </section>

    <section class="_section__riverside-cottage--grid">
      <GridGallery
        class="_grid__gallery"
        :gallery="houses[0].features"
      ></GridGallery>
      <!-- <ExpansionPanel class="_grid__expansion"> -->
      <div class="_card">
        <h2 class="_title">Experiences / Activities</h2>
        <div class="_paragraphs">
          <p class="_text">
            Enjoy privacy at the 2000 sq.ft. riverside cottage
          </p>
          <p class="_text">The cozy cottage provides room for upto 8 guests</p>
          <p class="_text">3 spacious and homely bedrooms for all guests</p>
          <p class="_text">1 comfortable queen size bed in all three rooms</p>
          <p class="_text">A porch that opens up to the serene Pej river</p>
          <p class="_text">Home-cooked veg meals prepared by our cook</p>
          <p class="_text">Play outdoor sports in the expansive open lawns</p>
          <p class="_text">Choose from a variety of indoor games for fun</p>
        </div>
      </div>
      <!-- </ExpansionPanel> -->
    </section>

    <section class="_section__riverside-cottage--sleeping-arrangements">
      <h2 class="_title">Sleeping Arrangements</h2>
      <div class="_card__custom">
        <div
          class="_sleeping-arrangement__item"
          v-for="room in houses[0].rooms"
          :key="room.id"
        >
          <div class="_subtitle">{{ room.title }}</div>
          <div class="_text" v-for="detail in room.details" :key="detail">
            {{ detail }}
          </div>
        </div>
      </div>
    </section>

    <section class="_section__riverside-cottage--amenities">
      <h2 class="_title">Amenities</h2>
      <div class="_card__custom">
        <div
          class="_amenities__item"
          v-for="amenity in houses[0].amenities"
          :key="amenity.id"
        >
          <img
            class="_amenities__icon"
            :src="amenity.iconPath"
            :alt="amenity.title"
          />
          <p class="_text">{{ amenity.title }}</p>
        </div>
      </div>
    </section>

    <section class="_section__slider--gallery">
      <h2 class="_title">Gallery</h2>
      <div class="_slider-content">
        <ContentSlider>
          <!-- <div class="_sliderGalleryWrapper" ref="sliderGalleryWrapper"> -->
          <picture
            class="_picture _slider__item"
            v-for="image in gallerySlider"
            :key="image.id"
          >
            <img
              loading="lazy"
              class="_image"
              :src="image.path"
              alt="Grid Gallery"
            />
            <div class="_caption">{{ image.caption }}</div>
          </picture>
        </ContentSlider>
      </div>
    </section>

    <section class="_section__testimonials">
      <h2 class="_title">Testimonials</h2>
      <div class="_slider">
        <div
          class="_card--light"
          v-for="item in this.testimonies"
          :key="item.id"
        >
          <div class="_content">
            <p class="_text">
              {{ item.comment }}
            </p>
            <div class="_rating">
              <h3 class="_rating__title">{{ item.name }}</h3>
              <div class="_rating__icons">
                <div
                  class="_rating__icon"
                  :class="{ '_rating__icon--active': i <= item.rating }"
                  v-for="i in 5"
                  :key="i"
                ></div>
              </div>
              <div class="_rating__image"></div>
            </div>
          </div>
        </div>
        <!-- <div></div> -->
      </div>
    </section>
  </div>
</template>

<script>
import GridGallery from "@/components/GridGallery.vue";
import ContentSlider from "@/components/ContentSlider.vue";
// import ExpansionPanel from "@/components/ExpansionPanel.vue";
export default {
  name: "RiversideCottage",
  metaInfo: {
    title: "3 Bedroom Riverside Cottage",
    tags: "",
  },
  data() {
    return {
      testimonies: [
        {
          id: 1,
          name: "Sumant Singh",
          comment:
            "Thanks for the great hospitality and services. We enjoyed our stay. The food was very homely and tasty. The staff is very understanding and helpful.",
          rating: 4,
        },
        {
          id: 2,
          name: "Dibyapran",
          comment:
            "Beautiful property with an amazing river front view. Friendly staff and delicious food.",
          rating: 4,
        },
        {
          id: 3,
          name: "Utsav Shah",
          comment:
            "The stay was amazing. Caretakers were really good and cooked delicious food.",
          rating: 4,
        },
      ],
      houses: [
        {
          id: 1,
          name: "3 Bedroom Riverside Cottage",
          rooms: [
            { title: "Bedroom 1", details: ["1 king bed", "1 queen bed"] },
            { title: "Bedroom 2", details: ["1 king bed", "1 queen bed"] },
            { title: "Bedroom 3", details: ["1 king bed", "1 queen bed"] },
          ],
          features: [
            {
              id: 1,
              title: "",
              path: require("@/assets/images/riverside_features_1-Small.jpg"),
            },
            {
              id: 2,
              title: "",
              path: require("@/assets/images/riverside_features_2-Small.jpg"),
            },
            {
              id: 3,
              title: "",
              path: require("@/assets/images/riverside_features_3-Small.jpg"),
            },
            {
              id: 4,
              title: "",
              path: require("@/assets/images/riverside_features_4-Small.jpg"),
            },
            {
              id: 5,
              title: "",
              path: require("@/assets/images/riverside_features_5-Small.jpg"),
            },
            // {
            //   id: 6,
            //   title: "",
            //   path: require("@/assets/images/riverside_features_6-Small.jpg"),
            // },
          ],
          amenities: [
            {
              id: 1,
              title: "Air Conditioning",
              iconPath: require(`${"@/assets/amenity 1.svg"}`),
            },
            {
              id: 2,
              title: "Free WiFi",
              iconPath: require("@/assets/amenity 2.svg"),
            },
            {
              id: 3,
              title: "Pet Friendly",
              iconPath: require("@/assets/amenity 3.svg"),
            },
            {
              id: 4,
              title: "Vegetarian Meals (extra charges applicable)",
              iconPath: require("@/assets/amenity 4.svg"),
            },
            // {
            //   id: 5,
            //   title: "Alcohol Allowed",
            //   iconPath: require("@/assets/amenity 5.svg"),
            // },
            {
              id: 5,
              title: "Genset Available (extra charges applicable)",
              iconPath: require("@/assets/amenity 6.svg"),
            },
            {
              id: 6,
              title: "Free parking on premises",
              iconPath: require("@/assets/amenity 7.svg"),
            },
            {
              id: 7,
              title: "Long-term stays allowed",
              iconPath: require("@/assets/amenity 8.svg"),
            },
            {
              id: 8,
              title: "Towels and Toiletries",
              iconPath: require("@/assets/amenity 9.svg"),
            },
          ],
        },
      ],
      gallerySlider: [
        {
          id: 1,
          path: require("@/assets/images/riverside_gallery_1-Small.jpg"),
          caption: "View from the River",
        },
        {
          id: 2,
          path: require("@/assets/images/riverside_gallery_2-Small.jpg"),
          caption: "Balcony & Garden",
        },
        {
          id: 3,
          path: require("@/assets/images/riverside_gallery_3-Small.jpg"),
          caption: "Balcony Seating",
        },
        {
          id: 4,
          path: require("@/assets/images/riverside_gallery_4-Small.jpg"),
          caption: "Living Room - Overview",
        },
        {
          id: 5,
          path: require("@/assets/images/riverside_gallery_5-Small.jpg"),
          caption: "Living Room - Dining",
        },
        {
          id: 6,
          path: require("@/assets/images/riverside_gallery_6-Small.jpg"),
          caption: "Living Room - Seating",
        },
        {
          id: 7,
          path: require("@/assets/images/riverside_gallery_7-Small.jpg"),
          caption: "Bedroom 1 - Overview",
        },
        {
          id: 8,
          path: require("@/assets/images/riverside_gallery_8-Small.jpg"),
          caption: "Bedroom 1 - Close Up",
        },
        {
          id: 9,
          path: require("@/assets/images/riverside_gallery_9-Small.jpg"),
          caption: "Bedroom 1 - Bathroom",
        },
        {
          id: 10,
          path: require("@/assets/images/riverside_gallery_10-Small.jpg"),
          caption: "Bedroom 2 - Overview",
        },
        {
          id: 11,
          path: require("@/assets/images/riverside_gallery_11-Small.jpg"),
          caption: "Bedroom 2 - Close Up",
        },
        {
          id: 12,
          path: require("@/assets/images/riverside_gallery_12-Small.jpg"),
          caption: "Bedroom 3 - Overview",
        },
        {
          id: 13,
          path: require("@/assets/images/riverside_gallery_13-Small.jpg"),
          caption: "Bedroom 3 - Close Up",
        },
        {
          id: 14,
          path: require("@/assets/images/riverside_gallery_14-Small.jpg"),
          caption: "Balcony & River",
        },
        {
          id: 15,
          path: require("@/assets/images/riverside_gallery_15-Small.jpg"),
          caption: "Pej River",
        },
      ],
    };
  },
  components: {
    GridGallery,
    ContentSlider,
    // ExpansionPanel,
  },
};
</script>

<style scoped lang="scss">
._riverside-cottage {
  ._section__riverside-cottage--intro {
    @extend ._section__custom--overlap;
    ._media {
      display: grid;
      grid-template-rows: 1fr auto 5fr;
      grid-template-columns: 0.5fr 1.5fr 1fr 3fr;
      ._picture {
        &--1 {
          @extend ._picture;
          grid-area: 2/2/3/4;
          z-index: 1;
        }
        &--2 {
          @extend ._picture;
          grid-area: 1/3/-1/-1;
        }
      }
    }
  }
  ._section__riverside-cottage--grid {
    @extend ._section__custom;
  }
  ._section__riverside-cottage--sleeping-arrangements {
    @extend ._section__custom;
    padding-bottom: $paddingMinimum;
    ._card__custom {
      padding: 0 $paddingMinimum;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    ._sleeping-arrangement__item {
      flex: 0 0 160px;
      @extend ._card--outlined;
      margin: 0;
    }
  }
  ._section__riverside-cottage--amenities {
    @extend ._section__custom;
    padding-bottom: $paddingMinimum;
    ._card__custom {
      padding: 0 $paddingMinimum;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    ._amenities__item {
      @extend ._card--raised;
      padding: 20px 30px;
      flex: 1 0 300px;
      margin: 0;
      display: flex;
      gap: 10px;
    }
    ._amenities__icon {
      // justify-self: start;
      // flex-basis: 40%;
      // width: 100px;
      height: 40px;
    }
  }
  ._section__slider--gallery {
    @extend ._section__custom;
    ._slider-content {
      max-height: 300px;
    }
  }
  ._section__testimonials {
    @extend ._section__custom;
    ._card--light {
      flex: 0 0 270px;
    }
  }
}

@media screen and (max-width: 400px) {
  ._riverside-cottage {
    ._section__riverside-cottage--intro {
      grid-template-rows: auto 50px auto;
      ._media {
        ._picture {
          &--1 {
            grid-area: 2/1/3/4;
          }
        }
      }
    }
    ._section__riverside-cottage--sleeping-arrangements {
      ._sleeping-arrangement__item {
        flex: 1 0 150px;
      }
    }
    ._section__riverside-cottage--amenities {
      ._amenities__item {
        flex: 1 0 100%;
      }
    }
  }
}
@media screen and (min-width: 600px) {
  ._riverside-cottage {
    ._section__riverside-cottage--intro {
      grid-template-columns: 90% 1fr;
      ._media {
        grid-template-rows: 2fr 7fr 5fr;
      }
      ._card--raised {
        grid-area: 2/1/4/2;
      }
    }
    ._section__slider--gallery {
      ._slider-content {
        max-height: 400px;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  ._riverside-cottage {
    ._section__riverside-cottage--intro {
      grid-template-rows: 1.2fr 1fr;
      grid-template-columns: 70% 1fr;
      ._media {
        max-height: 600px;
        grid-template-rows: 2fr 6fr 5fr;
      }
      ._card--raised {
        grid-area: 2/1/4/2;
      }
    }
    ._section__riverside-cottage--grid {
      grid-template-columns: 1fr 1.5fr;
      column-gap: Calc($paddingMinimum * 2);
      ._grid__gallery {
        grid-area: 1/2/2/3;
      }
    }
    ._section__riverside-cottage--sleeping-arrangements {
      padding-bottom: 0;
    }
    ._section__riverside-cottage--amenities {
      padding-bottom: 0;
    }
    ._section__slider--gallery {
      ._slider-content {
        max-height: 500px;
      }
    }
  }
}
</style>